import React from "react"
import Layout from "../components/layout"
import Slider from "react-slick"

export default class Post extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const s = this.state

        return (
            <Layout className="blog-post">
                <div className="main-image">
                    <img src="../img.jpg" alt=""/>
                </div>
                <div className="content">
                    <div className="info">
                        <h2>Sempre a Frente oznacza Zawsze do Przodu.</h2>
                        <p>Misja, która towarzyszy inicjowanym i realizowanym przez nas działaniom to Nauka i Rozwój
                            przez Doświadczanie.<br/><br/> Wierzymy, że nauka i rozwój przez doświadczanie są naturalnymi potrzebami człowieka.
                        </p>
                        <hr/>
                        <p>Poprzez swoje działania wspieramy <strong>dzieci, młodzież i dorosłych</strong> w zdobywaniu
                            kluczowych
                            kompetencji społecznych. Wspieramy osoby z niskimi kompetencjami społecznymi realizując
                            programy profilaktyczne, edukacyjne i informacyjne.</p>
                        <p>
                            Prowadzimy placówki wsparcia dziennego, które swoje działania kierują do dzieci w wieku 5-9
                            lat oraz 10-13 lat, a także młodzieży w wieku 13-18 oraz ich rodziców.</p>

                        <p> Poprzez pracę metodami edukacji pozaformalnej zachęcamy młodych ludzi do przyjęcia aktywnej
                            postawy obywatelskiej, tym samym promując odpowiedzialność społeczną, inicjatywność oraz
                            przedsiębiorczość.
                            Wspieramy aktywne uczestnictwo młodzieży w życiu społecznym, między innymi poprzez włączanie
                            w dialog usystematyzowany, czyli formę komunikacji między młodymi ludźmi a decydentami.</p>

                        <p> Ponadto wpieramy rozwój kompetencji psychospołecznych i zawodowych osób dorosłych prowadząc
                            m.in. treningi interpersonalne, warsztaty rozwoju osobistego oraz metod pracy z grupą.</p>

                        <p> Prowadzimy Regionalny Punkt Informacyjny Eurodesk oraz Regionalny Punkt Informacyjny
                            Programu Erasmus+, przez co dążymy do wzmocnienia działań w zakresie polityki młodzieżowej
                            na Lubelszczyźnie.</p>

                        <p> W latach 2015-2017 prowadziliśmy serwis informacyjny lublin.ngo.pl będący regionalnym
                            wydaniem największego serwisu informacyjnego dla organizacji pozarządzowych - ngo.pl</p>
                        <h5>Nasze działania realizujemy w trzech działach programowych:</h5>
                        <p><strong>Centrum Informacji i Rozwoju Młodzieży</strong>,<br/> którego ofertę kierujemy do
                            szkół, młodzieży
                            gimnazjalnej, ponagimnazjalnej i studentów;</p>
                        <p><strong>Świetlica Kowalska 3</strong>,<br/> gdzie prowadzimy zajęcia świetlicowe dla dzieci w
                            wieku 5-9 lat;</p>
                        <p><strong>Ośrodek Wsparcia Młodzieży Przystanek Sempre</strong>,<br/> którego działania są
                            skierowane do młodych osób
                            w wieku 10-18 lat oraz ich rodziców i opiekunów.</p>
                    </div>
                    <div className="blog-preview">
                        <div className="blog-preview__more">
                            <a href="/blog">Zobacz więcej działań <i className="icon-arrow-right"></i></a>
                        </div>
                        <div className="blog-preview__content">
                            <div className="item">
                                <div className="item__img">
                                    <img src="../21.png" alt=""/>
                                </div>
                                <div className="item__content">
                                    <h5>Swoimi działaniami wspieramy młodzież w rozwoju</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed laoreet ipsum,
                                        imperdiet
                                        efficitur elit. Praesent molestie elementum ante.</p>
                                    <div className="text-center">
                                        <a href="#" className="btn-dark">
                                            <span>Więcej</span>
                                            <i className="icon-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item__img">
                                    <img src="../21.png" alt=""/>
                                </div>
                                <div className="item__content">
                                    <h5>Swoimi działaniami wspieramy młodzież w rozwoju</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed laoreet ipsum,
                                        imperdiet
                                        efficitur elit. Praesent molestie elementum ante.</p>
                                    <div className="text-center">
                                        <a href="#" className="btn-dark">
                                            <span>Więcej</span>
                                            <i className="icon-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item__img">
                                    <img src="../21.png" alt=""/>
                                </div>
                                <div className="item__content">
                                    <h5>Swoimi działaniami wspieramy młodzież w rozwoju</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed laoreet ipsum,
                                        imperdiet
                                        efficitur elit. Praesent molestie elementum ante.</p>
                                    <div className="text-center">
                                        <a href="#" className="btn-dark">
                                            <span>Więcej</span>
                                            <i className="icon-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
